<template>
	<el-dialog :title="!dataForm.recNo ? '新增系泊试验进度' : '修改系泊试验进度'" :close-on-click-modal="false" :visible.sync="visible"
		width="900px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true">
			<el-row>
				<el-col :span="12">
					<el-form-item label="标的物" prop="subjectNo">
						<el-select class="selItemInput" v-model="dataForm.subjectNo" placeholder="请选择"
							@change="handleChange" :disabled="updateSortFlg">
							<el-option v-for="item in $store.state.common.navBarSubjectList" :key="item.displayValue"
								:label="item.displayName" :value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="序号" prop="sort">
						<el-input-number v-model="dataForm.sort" controls-position="right" :min="1" disabled="true"></el-input-number>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="专业" prop="testTypeId">
						<el-select class="selItemInput" v-model="dataForm.testTypeId" placeholder="请选择">
							<el-option v-for="item in mooringTestTypeList" :key="item.displayValue"
								:label="item.displayName" :value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="完成时间" prop="finishDate">
						<el-date-picker v-model="dataForm.finishDate" type="date" value-format="yyyy-MM-dd"
							placeholder="选择日期" class="selItemInput">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="系泊试验项目" prop="testItem">
						<el-input class="selItemInput descAreaLg" type="textarea" rows="3"
							v-model="dataForm.testItem"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="备注">
						<el-input class="selItemInput descAreaLg" type="textarea" rows="3"
							v-model="dataForm.remark"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button>
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
		</el-row>
	</el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
	name: "examination-add-or-update",
	data() {
		return {
			visible: false,
			inline: false,
			updateSortFlg: false,
			mooringTestTypeList: [],
			action: "",
			dataForm: {
				sort: '1',
				recNo: '',
				workNo: '',
				subjectNo: '',
				testItem: '',
				finishDate: '',
				testTypeId: '',
				remark: '',
				delFlag: '0'
			},
			dataRule: {
				testTypeId: [
					{ required: true, message: "请选择专业", trigger: "change" }
				],
				subjectNo: [
					{ required: true, message: "请选择标的物", trigger: "change" }
				],
				testItem: [
					{ required: true, message: "请输入系泊试验项目", trigger: "blur" }
				]
			},
		}
	},
	methods: {
		init(id) {
			this.visible = true;
			this.dataForm = {
				sort: '1',
				recNo: '',
				workNo: $common.getItem("workNo"),
				subjectNo: '',
				testItem: '',
				finishDate: '',
				testTypeId: '',
				remark: '',
				delFlag: '0'
			};
			this.dataForm.recNo = id ? id : '';
			this.getMooringTestTypeList();
			this.$nextTick(() => {
				this.$refs["dataForm"].resetFields();
				if (this.dataForm.recNo) {
					this.$http({
						url: this.$store.state.httpUrl + `/business/mooringtestprogressrecord/info/${this.dataForm.recNo}`,
						method: 'get'
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.dataForm = data.body;
							this.getMooringTestTypeList();
						}
					})
				}
			})
			if(this.dataForm.recNo) {
				this.updateSortFlg = true
			} else {
				this.updateSortFlg = false
			}
		},
		handleChange() {
			if (this.dataForm.subjectNo) {
				this.getMaxSort();
			}
		},
		getMaxSort() {
			let _url = "/business/mooringtestprogressrecord/getMaxSort";
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData({
					workNo: this.dataForm.workNo,
					subjectNo: this.dataForm.subjectNo,
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.$set(this.dataForm, 'sort', data.body)
				}
			});
		},
		getMooringTestTypeList(type) {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getMooringTestType?workNo=" + $common.getItem("workNo") + "&subjectNo=" + this.dataForm.subjectNo,
				method: "get",
				params: {},
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.mooringTestTypeList = data.body;
				}
			});
		},
		dataFormSubmit() {
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					let _url = "/business/mooringtestprogressrecord/save";
					if (this.dataForm.recNo && this.dataForm.recNo !== '') {
						_url = "/business/mooringtestprogressrecord/update";
					}
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData(this.dataForm)
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.visible = false;
									this.$parent.getDataList();
								}
							});
						}
					});
				}
			});
		}

	}
}
</script>
<style lang="scss" scoped="scoped">
.selItemInput.descAreaLg {
	width: 688px !important;
}
</style>
