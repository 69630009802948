<template>
	<!-- 巡视信息列表 -->
	<div class="mod-config">
		<el-card body-style="padding-bottom: 0;" style="margin-top: -5px;">
			<div class="title"> 系泊试验 </div>
			<div class="line"></div>
			<el-row
				style="margin-top: -10px;margin-left: -20px;line-height:30px;width: 103%;">
				<el-row style="margin-top: 10px;">
					<el-col :span="2">
						<div class="search_title">标的物</div>
					</el-col>
					<el-col :span="22" style="display: flex;flex-wrap: wrap;">
						<span v-for="(subject, index) in subjectList" style="margin-right: 20px;">
							<span class="search_bn_border" v-if="subjectNo === subject.displayValue"
								@click="setSubject(subject)">
								{{ subject.displayName }}
							</span>
							<span class="search_bn" v-else-if="subjectNo !== subject.displayValue"
								@click="setSubject(subject)">
								{{ subject.displayName }}
							</span>
						</span>
					</el-col>
				</el-row>
				<el-row style="margin-top: 17px;">
					<el-col :span="2">
						<div class="search_title">专业</div>
					</el-col>
					<el-col :span="22" style="display: flex;flex-wrap: wrap;">
						<span v-for="(maj, index) in majorList" style="margin-right: 20px;">
							<span class="search_bn_border" v-if="testTypeId === maj.displayValue" @click="setMajor(maj)">
								{{ maj.displayName }}
							</span>
							<span class="search_bn" v-else-if="testTypeId !== maj.displayValue" @click="setMajor(maj)">
								{{ maj.displayName }}
							</span>
						</span>
					</el-col>
				</el-row>
				<div class="line"></div>
				<el-row style="background-color: rgb(243, 247, 247);margin-top: -10px;height: 45px;width: 103%;">
					<el-col :span="23" style="margin-left:2px;margin-top:5px">
						<el-button v-preventReClick class="btn" type="primary" size="small" :disabled="updateFlg || addFlg"
							v-if="showAdd" @click="addHandle()" style="margin-left: 15px;">添加</el-button>
						<el-button v-preventReClick :disabled="disabledBatchDelete || updateFlg || addFlg" class="btn"
							type="primary" size="small" @click="deleteBatch()">删除</el-button>
						<!-- <el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-folder" v-if="showFileKindSet"
						 @click="testTypeSettingHandle()">专业设定</el-button> -->
						<el-button v-preventReClick class="btn" type="primary" size="small" :disabled="updateFlg || addFlg"
							@click="uploadHandle()">导入</el-button>
						<el-button v-preventReClick class="btn" type="primary" size="small" :disabled="updateFlg || addFlg"
							@click="downloadHandle()">导出</el-button>
						<el-button v-preventReClick class="btn" type="primary" size="small" :disabled="updateFlg || addFlg"
							@click="downloadTemplate()">模板下载</el-button>
					</el-col>
				</el-row>
				<div class="line" style="margin-top: -3px;"></div>
				<el-form ref="form" :model="form" inline label-width="80px" label-position="left">
					<el-row
						style="background-color: rgb(243, 247, 247);margin-top: -10px;height: 45px;width: 103%;">
						<el-col :span="18" style="margin-left:20px">
							<!-- <el-form-item label="标的物" class="selFormItem">
							<el-select class="selSubjectInput" v-model="subjectNo" placeholder="请选择" :disabled="updateFlg || addFlg">
								<el-option v-for="item in $store.state.common.navBarSubjectList" :key="item.displayValue"
									:label="item.displayName" :value="item.displayValue">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="专业" class="selFormItem">
							<el-select class="selMajorInput" v-model="testTypeId" placeholder="请选择" :disabled="updateFlg || addFlg">
								<el-option v-for="item in mooringTestTypeList" :key="item.displayValue"
									:label="item.displayName" :value="item.displayValue">
								</el-option>
							</el-select>
						</el-form-item> -->
							<el-form-item label="完成时间" class="selFormItem" style="margin-top:3px">
								<el-date-picker class="selDateInput" v-model="examineTime" type="daterange"
									value-format="yyyy-MM-dd" range-separator="~" start-placeholder="开始日期"
									end-placeholder="结束日期" :disabled="updateFlg || addFlg">
								</el-date-picker>
							</el-form-item>
							<el-form-item label="系泊试验项目" class="selFormItem1">
								<el-input class="selSearchInput" type="input" v-model="testItem" placeholder="请输入"
									:disabled="updateFlg || addFlg" clearable></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="4" class="selBtnGroup" style="margin-top:5px">
							<el-button v-preventReClick class="btn" type="primary" size="small" @click="selGetDataList()"
								:disabled="updateFlg || addFlg" v-if="showSearch">查询</el-button>
							<el-button v-preventReClick class="rebtn" size="small" :disabled="updateFlg || addFlg"
								@click="resetFormData()">重置</el-button>
						</el-col>
					</el-row>
				</el-form>
			</el-row>
		</el-card>
		<div>
			<el-card style="margin-top: -25px; height: 450px;" body-style="height:410px; padding: 0;">
				<el-row style="height: 100%; margin-bottom: 10px;">
					<el-col :span="24" style="height: 100%;">
						<div class="box">
							<el-table height="390" :row-class-name="tableRowClassName" :row-style="selectRowBackground"
								ref="dataListTable" class="dataListTable" :data="dataList" header-align="center" border
								style="width: 100%; margin-bottom: 20px;" header-cell-class-name="dataListTHeader"
								@selection-change="handleSelectionChange" :cell-style="{ padding: '0px' }">
								<el-table-column type="selection" width="50" align="center"></el-table-column>
								<el-table-column type="index" label="NO." width="60" align="center">
									<template slot-scope="scope">{{ (pageIndex2 - 1) * pageSize + scope.$index + 1
									}}</template>
								</el-table-column>
								<!-- <el-table-column prop="subjectName" label="标的物" width="120" align="left">
									<template slot-scope="scope">
										<span style="margin-left: 15px;" v-if="!getDisabled(scope.row.recNo)">{{
											scope.row.subjectName
										}}</span>
										<el-input class="sp-input" v-if="getDisabled(scope.row.recNo) && !addFlg"
											:disabled="true" v-model="scope.row.subjectName"></el-input>
										<el-select class="sp-input" v-model="scope.row.subjectNo" placeholder="请选择"
											v-if="getDisabled(scope.row.recNo) && addFlg" style="width:110px">
											<el-option v-for="item in $store.state.common.navBarSubjectList"
												:key="item.displayValue" :label="item.displayName"
												:value="item.displayValue">
											</el-option>
										</el-select>
									</template>
								</el-table-column>
								<el-table-column prop="testTypeIdName" label="专业" width="110" align="center">
									<template slot-scope="scope">
										<span style="margin-left: 15px;" v-if="!getDisabled(scope.row.recNo)">{{
											scope.row.testTypeIdName
										}}</span>
										<el-select class="sp-input" v-model="scope.row.testTypeId" placeholder="请选择"
											v-if="getDisabled(scope.row.recNo)" style="width:100px">
											<el-option v-for="item in majorList" :key="item.displayValue"
												:label="item.displayName" :value="item.displayValue">
											</el-option>
										</el-select>
									</template>
								</el-table-column> -->
								<el-table-column prop="testItem" label="系泊试验项目" align="left">
									<template slot-scope="scope">
										<span style="margin-left: 15px;" v-if="!getDisabled(scope.row.recNo)">{{
											scope.row.testItem
										}}</span>
										<el-input class="sp-input" v-if="getDisabled(scope.row.recNo)"
											v-model="scope.row.testItem"></el-input>
									</template>
								</el-table-column>
								<el-table-column prop="finishDate" label="完成时间" width="180px" align="center">
									<template slot-scope="scope">
										<span style="margin-left: 15px;" v-if="!getDisabled(scope.row.recNo)">{{
											scope.row.finishDate
										}}</span>
										<el-date-picker class="sp-input" v-model="scope.row.finishDate" type="date"
											value-format="yyyy-MM-dd" placeholder="选择日期" style="width: 150px;"
											v-if="getDisabled(scope.row.recNo)">
										</el-date-picker>
									</template>
								</el-table-column>
								<el-table-column prop="remark" label="备注 " width="220" align="left">
									<template slot-scope="scope">
										<span style="margin-left: 15px;" v-if="!getDisabled(scope.row.recNo)">{{
											scope.row.remark
										}}</span>
										<el-input class="sp-input" v-if="getDisabled(scope.row.recNo)"
											v-model="scope.row.remark"></el-input>
									</template>
								</el-table-column>
								<el-table-column label="操作" width="200" v-if="showDelete || showEdit" align="center">
									<template slot-scope="scope">
										<el-button v-preventReClick type="text" size="small"
											@click="updateHandle(scope.row)"
											v-if="showEdit && !updateFlg && !addFlg">修改</el-button>
										<el-button v-preventReClick type="text" size="mini"
											@click="deleteHandle(scope.row.recNo)"
											v-if="showDelete && !updateFlg && !addFlg">删除</el-button>
										<!--新增时按钮-->
										<el-button v-preventReClick type="text" size="small" @click="saveMooringTest()"
											v-if="scope.row.recNo == undefined && addFlg">保存</el-button>
										<el-button v-preventReClick type="text" size="small" @click="cancelSaveHandle()"
											v-if="scope.row.recNo == undefined && addFlg">取消</el-button>
										<!--修改时按钮-->
										<el-button v-preventReClick type="text" size="small"
											@click="updateMooring(scope.row)"
											v-if="showEdit && getDisabled(scope.row.recNo) && !addFlg">保存</el-button>
										<el-button v-preventReClick type="text" size="small" @click="cancelUpdateHandle()"
											v-if="showEdit && getDisabled(scope.row.recNo) && !addFlg">取消</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<el-pagination class="my-pagination" @size-change="sizeChangeHandle"
							@current-change="currentChangeHandle" :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]"
							:page-size="pageSize" :total="totalPage" layout="total, sizes, prev, pager, next, jumper"
							:disabled="updateFlg || addFlg">
						</el-pagination>
					</el-col>
				</el-row>
			</el-card>
		</div>
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList()"></add-or-update>
		<test-type-add-or-update v-if="testTypeVisible" ref="testTypeAddOrUpdate"
			@refreshDataList="getDataList()"></test-type-add-or-update>
		<excel-upload v-if="excelUploadVisible" ref="excelUpload" @refreshDataList="dataList"></excel-upload>
	</div>
</template>
<script>
import TestTypeAddOrUpdate from "./mooringTest-type-setting"
import AddOrUpdate from './mooringTest-add-or-update'
import ExcelUpload from './mooringTest-excel-upload'
import $common from "@/utils/common.js"
export default {
	data() {
		return {
			showSearch: true,
			showAdd: true,
			showFileKindSet: true,
			showEdit: true,
			showDelete: true,
			disabledBatchDelete: true,
			updateFlg: false,
			addFlg: false,
			pageSpan: 12,
			mooringTestTypeList: [],
			testTypeId: '',
			subjectNo: '',
			tabIndex: '',
			testTypeVisible: false,
			addOrUpdateVisible: false,
			form: {},
			examineTime: [],
			testItem: '',
			dataList: [],
			tableSelVal: [],
			tableSelIdArray: [],
			tableSelVal: [],
			selAllFlg: false,
			excelUploadVisible: false,
			closeTime: [],
			//审查文件类型
			documentTypeList: [],
			//当前页码
			pageIndex: 1,
			// 显示序号
			pageIndex2: 1,
			//当前记录数
			pageSize: 20,
			//总页数
			totalPage: 0,
			recNos: [],
			subjectList: [],
			majorList: [],
			options: [
				{
					value: "0",
					label: "未审查"
				},
				{
					value: "1",
					label: "审查"
				}
			],
			option: [
				{
					value: "0",
					label: "未返回"
				},
				{
					value: "1",
					label: "返回"
				}
			],
		};
	},
	components: {
		AddOrUpdate,
		TestTypeAddOrUpdate,
		ExcelUpload
	},
	mounted() {
		// this.showSearch = $common.isAuth('mooringTest:search');
		// this.showFileKindSet = $common.isAuth('mooringTest:file:kind:set');
		// this.showAdd = $common.isAuth('mooringTest:add');
		// this.showEdit = $common.isAuth('mooringTest:edit');
		// this.showDelete = $common.isAuth('mooringTest:delete');
		if (!this.showFileKindSet && !this.showAdd) {
			this.pageSpan = 24;
		}
		this.pageIndex2 = 1;
		// this.getMooringTestTypeList();
		this.getSubjectDropDown();
		this.getMajorList();
		// this.getDataList();
	},
	methods: {
		addHandle() {
			// 首行新增一条记录
			let dataList = [];
			let mooringTest = {
				workNo: $common.getItem("workNo"),
				subjectName: '',
				subjectNo: this.subjectNo,
				testTypeId: this.testTypeId,
				testTypeIdName: '',
				finishDate: '',
				testItem: '',
				remark: '',
				sort: ''
			}
			dataList.push(mooringTest);
			for (var i = 0; i < this.dataList.length; i++) {
				dataList.push(this.dataList[i])
			}
			this.dataList = dataList
			this.addFlg = true;
		},
		cancelSaveHandle() {
			this.addFlg = false;
			let dataList = [];
			for (var i = 0; i < this.dataList.length; i++) {
				if (this.dataList[i].recNo != undefined) {
					dataList.push(this.dataList[i])
				}
			}
			this.dataList = dataList
		},
		updateHandle(val) {
			this.recNos.push(val.recNo)
			this.updateFlg = true;
		},
		cancelUpdateHandle() {
			this.getDataList()
			this.recNos = [];
			this.updateFlg = false;
		},
		getDisabled(val) {
			if (val == '' || val == undefined) {
				return true;
			} else {
				if (this.recNos.includes(val) && this.updateFlg) {
					return true;
				}
				return false;
			}
		},
		uploadHandle() {
			this.excelUploadVisible = true;
			this.$nextTick(() => {
				this.$refs.excelUpload.init()
			})
		},
		downloadHandle() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/mooringtestprogressrecord/download",
				method: 'get',
				params: this.$http.adornParams({
					workNo: $common.getItem("workNo"),
					subjectNo: this.subjectNo,
					testTypeId: this.testTypeId,
					beginTime: this.examineTime[0] ? this.examineTime[0] : "",
					endTime: this.examineTime[1] ? this.examineTime[1] : "",
					testItem: this.testItem,
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					if (this.$store.state.showTestFlag) {
						window.open(data.body)
					} else {
						this.global.downLoadFile(data.body, '系泊试验进度表.xlsx');
					}
					// this.global.downLoadFile(data.body,'系泊试验进度表.xlsx');
					// window.open(data.body)
					// this.dataList = data.body.records;
					// this.totalPage = data.body.totalCount;
				} else {
				}
			})
		},
		downloadTemplate() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getTemplateUrl?type=7",
				method: 'get',
				params: {}
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					if (data.body) {
						// window.open(data.body);
						if (this.$store.state.showTestFlag) {
							window.open(data.body)
						} else {
							this.global.downLoadFile(data.body, '系泊试验进度表模板.xlsx');
						}
					} else {
						this.$message.error("未配置模板，请联系运维管理人员")
					}
				}
			});
		},
		getMooringTestType(type) {
			let rtn = "";
			this.mooringTestTypeList.forEach(function (item) {
				if (item.displayValue === type) {
					rtn = item.displayName;
				}
			});
			return rtn;
		},
		getMooringTestTypeList() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getMooringTestType?workNo=" + $common.getItem("workNo") + "&subjectNo=" + this.subjectNo,
				method: "get",
				params: {},
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.mooringTestTypeList = data.body;
				}
			});
		},
		getSubjectDropDown() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.subjectList = data.body;
					if (this.subjectList != undefined && this.subjectList.length > 0) {
						this.subjectNo = this.subjectList[0].displayValue
						if (this.showSearch && this.testTypeId != null && this.testTypeId !='') {
							this.getDataList();
						}
					}
				}
			});
		},
		getMajorList() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getMajorByWorkNo/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.majorList = data.body;
					if (this.majorList != undefined && this.majorList.length > 0) {
						this.testTypeId = this.majorList[0].displayValue
						if (this.showSearch) {
							this.getDataList();
						}
					}
				}
			});
		},
		setMajor(major) {
			if (!this.addFlg && !this.updateFlg) {
				this.testTypeId = major.displayValue
				this.getDataList()
			}
		},
		setSubject(subject) {
			if (!this.addFlg && !this.updateFlg) {
				this.subjectNo = subject.displayValue
				this.getDataList()
			}
		},
		//获取数据
		getDataList() {
			if (this.showSearch) {
				this.$http({
					url: this.$store.state.httpUrl + "/business/mooringtestprogressrecord/list",
					method: "get",
					params: {
						workNo: $common.getItem("workNo"),
						subjectNo: this.subjectNo,
						testTypeId: this.testTypeId,
						beginTime: this.examineTime[0] ? this.examineTime[0] : "",
						endTime: this.examineTime[1] ? this.examineTime[1] : "",
						testItem: this.testItem,
						limit: this.pageSize,
						page: this.pageIndex
					},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.dataList = data.body.records;
						this.totalPage = data.body.totalCount;
						this.pageIndex2 = this.pageIndex
					}
				});
			}
		},
		saveMooringTest() {
			if (this.dataList[0].subjectNo == null || this.dataList[0].subjectNo == "") {
				this.$message.error("请选择标的物");
				return;
			}

			if (this.dataList[0].testTypeId == null || this.dataList[0].testTypeId == "") {
				this.$message.error("请选择专业");
				return;
			}

			if (this.dataList[0].testItem == null || this.dataList[0].testItem == "") {
				this.$message.error("请填写系泊试验项目");
				return;
			}

			let _url = "/business/mooringtestprogressrecord/getMaxSort";
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData({
					workNo: this.dataList[0].workNo,
					subjectNo: this.dataList[0].subjectNo,
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataList[0].sort = data.body
					_url = "/business/mooringtestprogressrecord/save";
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData(this.dataList[0])
					}).then(({
						data
					}) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
							});
							this.getDataList()
							this.addFlg = false
						}
					});
				}
			});
		},
		updateMooring(val) {
			if (val.testItem == null || val.testItem == "") {
				this.$message.error("请填写系泊试验项目");
				return;
			}
			let _url = "/business/mooringtestprogressrecord/update";
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData(val)
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
					});
					this.recNos = []
					this.getDataList()
					this.updateFlg = false
				}
			});
		},
		testTypeSettingHandle() {
			this.testTypeVisible = true;
			this.$nextTick(() => {
				this.$refs.testTypeAddOrUpdate.init();
			})
		},
		//删除
		deleteHandle(id) {
			this.recNo = id ? id : 0;
			this.$confirm(`确定对选中的数据进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + `/business/mooringtestprogressrecord/delete/${this.recNo}`,
					method: "POST",
					data: this.$http.adornData({
						recNo: id
					})
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				});
			})
		},
		siteContentViewHeight() {
			var height = this.$store.state.common.documentClientHeight - 300;
			return { height: height + 'px' }
		},
		// 审核
		auditeHandle(id) {
			this.auditVisible = true
			this.$nextTick(() => {
				this.$refs.audit.init(id)
			})
		},
		handleSelectionChange(selection) {
			this.tableSelVal = selection;
			if (selection.length > 0) {
				this.disabledBatchDelete = false;
			} else {
				this.disabledBatchDelete = true;
			}
		},
		deleteBatch() {
			this.$confirm(`确定对选中的数据进行删除操作？`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let records = this.tableSelVal.map((item) => item.recNo);
				this.$http({
					url: this.$store.state.httpUrl + "/business/mooringtestprogressrecord/deleteBatch",
					method: 'post',
					data: this.$http.adornData({
						records: records
					})
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				});
			});
		},
		// 每页数
		sizeChangeHandle(val) {
			this.pageSize = val
			this.pageIndex = 1
			this.getDataList()
		},
		// 当前页
		currentChangeHandle(val) {
			this.pageIndex = val
			this.getDataList()
		},
		tableRowClassName({ row, rowIndex }) {
			let color = ''
			for (let item of this.tableSelVal) {
				if (item.id === row.id) color = 'table-SelectedRow-bgcolor'
			}
			return color
		},
		addOrUpdateHandle(id) {
			this.addOrUpdateVisible = true;
			this.$nextTick(() => {
				this.$refs.addOrUpdate.init(id)
			})
		},
		setCategory(value) {
			this.fileType = value;
			this.pageIndex = 1;
			this.getDataList();
		},
		selGetDataList() {
			this.pageIndex = 1;
			this.getDataList();
			this.recNos = []
			this.updateFlg = false
		},
		resetFormData() {
			// this.subjectNo = ''
			// this.testTypeId = '';
			this.pageIndex = 1;
			this.examineTime = [];
			this.testItem = '';
			this.getMooringTestTypeList();
			this.getDataList();
			this.recNos = []
			this.updateFlg = false
		},
		tableRowClassName({ row, rowIndex }) {
			return $common.tableRowClassName({ row, rowIndex });
		},
		selectRowBackground({ row }) {
			const checkIdList = this.tableSelVal.map((item) => item.recNo);
			if (checkIdList.includes(row.recNo)) {
				return {
					backgroundColor: "#fae8cb",
				};
			}
		},

	}
};
</script>
<style scoped="scoped">
.selItemText {
	font-size: 14px;
	color: #1c1c1c;
	display: inline-block;
	width: 80px;
}

.sp-input /deep/ .el-input__inner {
	width: 100% !important;
}

.my-pagination {
	margin-right: 20px !important;
}

::v-deep(.el-table th.el-table__cell) {
	/* background-color: #d3e2f4 !important; */
	font-size: 15px;
	padding: 2px 0;
}


::v-deep(.selSubjectInput .el-input__inner) {
	width: 120px !important;
	height: 30px !important;
}

::v-deep(.selMajorInput .el-input__inner) {
	width: 110px !important;
	height: 30px !important;
}

.selDateInput {
	width: 280px !important;
	margin-top: 2px;
	height: 32px;

	/* ::v-deep .el-input__inner {
		height: 33px;
	} */
}

::v-deep(.selSearchInput .el-input__inner) {
	width: 200px !important;
	height: 30px !important;
}

::v-deep(.selDateInput .el-range-separator) {
	width: 40px !important;
	margin-top: -8px;
}

::v-deep(.selFormItem .el-form-item__label) {
	font-size: smaller;
}

::v-deep(.selFormItem1 .el-form-item__label) {
	font-size: smaller;
	width: 100px !important;
}
</style>
