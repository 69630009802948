<template>
	<el-dialog title="系泊试验专业设定" :close-on-click-modal="false" :visible.sync="visible" width="1000px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true">
			<el-row>
				<el-col :span="16">
					<el-form-item label="标的物" prop="subjectNo">
						<el-select class="selItemInput" v-model="dataForm.subjectNo" placeholder="请选择">
							<el-option v-for="item in $store.state.common.navBarSubjectList" :key="item.displayValue"
									   :label="item.displayName" :value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="专业" prop="testTypeName">
						<el-input class="selItemInput" v-model="dataForm.testTypeName" placeholder="请输入"
							style="width:300px">
						</el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">保存</el-button>
				</el-col>
			</el-row>
		</el-form>
		<el-row>
			<el-table ref="dataListTable" class="dataListTable" :data="dataList" header-cell-class-name="dataListTHeader" header-align="center" style="width: 100%; max-height: 500px;">
				<el-table-column
					width="80"
					type="index"
					align="center">
				</el-table-column>
				<el-table-column prop="subjectName" label="标的物" align="center"></el-table-column>
				<el-table-column prop="testTypeName" label="专业" align="center">
				</el-table-column>
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<el-button v-preventReClick type="warning" size="small" @click="addOrUpdateHandle(scope.row.recNo,scope.row.testTypeName)">修改
						</el-button>
						<el-button v-preventReClick type="danger" size="small" @click="deleteHandle(scope.row.recNo)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-row>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">关闭</el-button>
		</el-row>
	</el-dialog>

</template>

<script>
	import $common from "@/utils/common.js"
	export default {
		data() {
			return {
				visible: false,
				inline: false,
				dataList: [],
				dataForm: {
					recNo:"",
					testTypeName:"",
					delFlag:"0",
					workNo: $common.getItem("workNo"),
				},
				dataRule: {
					subjectNo: [
						{ required: true, message: "标的物不能为空", trigger: "blur" }
					],
					testTypeName: [
						{ required: true, message: "专业不能为空", trigger: "blur" }
					],
				},
			}
		},
		methods: {
			init() {
				this.visible = true;
				this.dataForm = {
					recNo:"",
					testTypeName:"",
					delFlag:"0",
					workNo: $common.getItem("workNo"),
					subjectNo:'',
				};
				this.$nextTick(() => {
					this.getInfo();
				})
			},
			getInfo(){
				this.$http({
					url: this.$store.state.httpUrl + '/business/mooringtesttype/list',
					method: 'get',
					params: this.$http.adornParams({
						workNo: $common.getItem("workNo"),
						page: 1,
						limit: 100
					})
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.dataList = data.body.records;
					}
				})
			},
			dataFormSubmit() {
				this.$refs["dataForm"].validate(valid => {
					if (valid) {
						let _url = "/business/mooringtesttype/save";
						if (this.dataForm.recNo && this.dataForm.recNo != 0) {
							_url = "/business/mooringtesttype/update";
						}
						this.$http({
							url: this.$store.state.httpUrl + _url,
							method: "post",
							data: this.dataForm
						}).then(({
							data
						}) => {
							if (data && data.resultCode === 200) {
								this.$message({
									message: "操作成功",
									type: "success",
									duration: 1500,
									onClose: () => {
										this.getInfo()
									}
								});
							} else {
								this.$message.error(data.msg);
							}
						});
					}
				});
			},
			//删除
			deleteHandle(id){
			    this.$confirm(`确定对选中的数据进行删除操作?`, '提示', {
			        confirmButtonText: '确定',
			        cancelButtonText: '取消',
			        type: 'warning'
			    }).then(() => {
			        this.$http({
			            url:  this.$store.state.httpUrl + `/business/mooringtesttype/delete/${id}`,
			            method: "GET",
						params: {},
			        }).then(({ data }) => {
			            if (data && data.resultCode === 200) {
			                this.$message({
			                    message: '操作成功',
			                    type: 'success',
			                    duration: 1500,
			                    onClose: () => {
									this.getInfo()
			                    }
			                })
			            } else {
			                this.$message.error(data.msg)
			            }
			        });
			    })
			},
			addOrUpdateHandle(id,name){
				this.dataForm.recNo = id;
				this.dataForm.testTypeName = name;
			},
		}
	}
</script>
<style scoped>

</style>
